@import "node_modules/bootstrap/scss/bootstrap";

.style-button {
    &.active {
        font-weight: bold;
        color: var(--primary-color);
    }
}

.editor-ul, .editor-ol {
    list-style-type: none;
    margin-left: 0;
    padding-left: 20px;
}

.editor-ul li, .editor-ol li {
    padding-left: 0;
}
@import "node_modules/bootstrap/scss/bootstrap";

:root {
	--primary-color:  #1cb15a;
	--primary-active: #1d8549;

	--danger: #d6334e;
	--danger-hover: #c52f4e;
	--danger-active: #cd304a;
	--danger-disabled: #d6334e;

	--resume: #696969;
	--resume-active: #A9A9A9;
	--resume-hover: #A9A9A9;

	--pause: #8B0000;
	--pause-active: #7a0000;
	--pause-hover: #7a0000;
}

html {
	height: 100%;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	padding-left: env(safe-area-inset-left);
	padding-right: env(safe-area-inset-right);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@supports (padding: max(0px)) {
	body,
	header {
		padding-left: min(0vmin, env(safe-area-inset-left));
		padding-right: min(0vmin, env(safe-area-inset-right));
	}
}
/* limit line */
.limit-1-line {
	max-height: 18px; 
	overflow: hidden;
}
.limit-2-line {
	max-height: 58px; 
	overflow: hidden;
}

.md-hide {
	display: inline;
}

.mt-center {
	margin-top: 250px;
}

@media (max-width: 768px) {
	.limit-2-line {
		max-height: 38px; 
		overflow: hidden;
	}	

	.md-hide {
		display: none;
	}

	.mt-center {
		margin-top: 100px;
	}
}

.bottom-left {
	position: absolute;
	bottom: 20px;

	width: 97.5%;
}

.bottom-half-right {
	position: absolute;
	bottom: 20px;

	width: 47.5%;
	right: 2.5%;
}

.bottom-half-left {
	position: absolute;
	bottom: 20px;

	width: 45%;
	left: 2.5%;
}

@media (max-width: 768px) {
	.bottom-half-left {
		bottom: 70px;

		width: 95%;
		left: 2.5%;
	}

	.bottom-half-right {
		width: 95%;
		right: 2.5%;
	}
}

.mt-6 {
	margin-top: 65px;
}

.container-xl {
	max-width: 1920px;
	--bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
	margin-left: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.clamp-1-line {
	display: block;       /* Ensures the element is treated as a block-level element */
    max-width: 100%;      /* Limits the width to the parent container's width */
    white-space: nowrap;  /* Keeps text on a single line */
    overflow: hidden;     /* Hides text that overflows the element's box */
    text-overflow: ellipsis; /* Adds an ellipsis to indicate hidden overflow text */
}

.clamp-two-lines {
	display: inline-block;
	height: calc(2em + 1px);
	line-height: 1em;
	overflow: hidden;
	text-overflow: ellipsis;
}

.clamp-2-line {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em;
    height: 3em;
}

.nav-link:hover {
	color: var(--primary-color) !important;
}

.hover-primary {
	&:hover {
		color: var(--primary-color)
	}
}

.hover {
	&:hover {
		color: var(--primary-color);
	}
}

blockquote {
    background-color: rgb(241, 241, 238);
	padding: 20px 20px 20px 40px;
	font-style: italic;
    text-align: center;
}